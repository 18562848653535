<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.default_data') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">

              <div class="col-lg-6 mb-5">
                <label>{{ $t('default_data.country') }}</label>
                <div class="input-group">
                  <multiselect
                      v-model="country"
                      :class="validation && validation.country_id ? 'is-invalid' : ''"
                      :internal-search="false"
                      :multiple="false"
                      :options="countries"
                      :placeholder="$t('users.countries')"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      :taggable="false"
                      label="name"
                      track-by="id"
                      @search-change="getCountries($event)"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('default_data.city') }}</label>
                <div class="input-group">
                  <multiselect
                      v-model="city"
                      :class="validation && validation.city ? 'is-invalid' : ''"
                      :multiple="false"
                      :options="cities"
                      :placeholder="$t('users.cities')"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      :taggable="false"
                      label="name"
                      track-by="id"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('default_data.currency') }}</label>
                <select id="currency_id" v-model="data.currency_id"
                        :class="validation && validation.currency_id ? 'is-invalid' : ''" class="custom-select"
                        name="">
                  <option v-for="row in currencies" :key="row.id" :value="row.id">{{ row.name }}</option>
                </select>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.currency_id[0] }}
                </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('default_data.unit') }}</label>
                <multiselect v-model="unit"
                             :class="validation && validation.unit_id ? 'is-invalid' : ''"
                             :multiple="false"
                             :options="units"
                             :placeholder="$t('default_data.unit')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getSelectUnit">
                </multiselect>
                <span v-if="validation && validation.unit_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.unit_id[0] }}
                </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('payment_purchase_invoices.treasuries') }}</label>
                <multiselect v-model="treasuries_objs"
                             :class="validation && validation.special_treasuries ? 'is-invalid' : ''"
                             :multiple="true"
                             :options="treasuries"
                             :placeholder="$t('payment_purchase_invoices.treasuries')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getMultiSelectTreasuries">
                </multiselect>
                <span v-if="validation && validation.special_treasuries"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.special_treasuries[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('banks.banks') }}</label>
                <multiselect v-model="banks_objs"
                             :class="validation && validation.special_banks ? 'is-invalid' : ''"
                             :multiple="true"
                             :options="banks"
                             :placeholder="$t('banks.banks')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getMultiSelectBanks">
                </multiselect>
                <span v-if="validation && validation.special_banks"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.special_banks[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('default_data.inventory') }}</label>
                <multiselect v-model="inventory"
                             :class="validation && validation.inventory_id ? 'is-invalid' : ''"
                             :multiple="false"
                             :options="inventories"
                             :placeholder="$t('default_data.inventory')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getSelectInventory">
                </multiselect>
                <span v-if="validation && validation.inventory_id"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.inventory_id[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('default_data.branch') }}</label>
                <multiselect v-model="branch"
                             :class="validation && validation.branch_id ? 'is-invalid' : ''"
                             :multiple="false"
                             :options="branches"
                             :placeholder="$t('default_data.branch')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getSelectBranch">
                </multiselect>
                <span v-if="validation && validation.branch_id"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.branch_id[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('default_data.department') }}</label>
                <multiselect v-model="department"
                             :class="validation && validation.department_id ? 'is-invalid' : ''"
                             :multiple="false"
                             :options="departments"
                             :placeholder="$t('default_data.department')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getSelectDepartment">
                </multiselect>
                <span v-if="validation && validation.department_id"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.department_id[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('default_data.workstation') }}</label>
                <multiselect v-model="workstation"
                             :class="validation && validation.workstation_id ? 'is-invalid' : ''"
                             :multiple="false"
                             :options="workstations"
                             :placeholder="$t('default_data.workstation')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getSelectWorkstation">
                </multiselect>
                <span v-if="validation && validation.workstation_id"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.workstation_id[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('customers.categories') }}</label>
                <multiselect v-model="categories_objs"
                             :class="validation && validation.special_banks ? 'is-invalid' : ''"
                             :multiple="true"
                             :options="categories"
                             :placeholder="$t('customers.categories')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="label"
                             track-by="id"
                             @input="getMultiSelectCategories">
                </multiselect>
                <span v-if="validation && validation.categories"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.categories[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox v-model="data.apply_default" name="check-button" size="lg" switch>
                  {{ $t('default_data.apply_default') }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button class="btn btn-primary mr-2" type="reset" @click="save()">
              {{ $t('save') }}
            </button>
            <button class="btn btn-secondary" type="reset">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-default-data",
  data() {
    return {
      mainRoute: 'default_data_user',
      mainRouteDependency: 'base/dependency',
      data: {
        user_id: this.$route.params.id ? this.$route.params.id : null,
        country_id: null,
        city_id: null,
        currency_id: null,
        inventory_id: null,
        branch_id: null,
        department_id: null,
        workstation_id: null,
        apply_default: true,
        unit_id: null,
        tax_id: null,
        special_treasuries: [],
        special_banks: [],
        categories: [],
      },
      validation: null,
      inventory: null,
      branch: null,
      department: null,
      workstation: null,
      country: null,
      city: null,
      unit: null,
      tax: null,
      full_name: null,

      treasuries: [],
      treasuries_objs: null,

      banks: [],
      banks_objs: null,

      categories: [],
      categories_objs: null,

      inventories: [],
      branches: [],
      departments: [],
      workstations: [],
      countries: [],
      cities: [],
      currencies: [],
      units: [],
      taxes: [],

    };
  },

  watch: {
    "country": function (val) {
      if (val && val.id) {
        this.data.country_id = val.id;
        this.getCities(val.code2);
      }
    },
    "city": function (val) {
      if (val) {
        this.data.city_id = val.id;
      }
    },
  },
  methods: {
    save() {
      this.create();
    },

    create() {
      ApiService.post(this.mainRoute + '/' + this.data.user_id, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push({name: 'users.index'});
      })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },
    getTreasuriesOption() {
      ApiService.get(`${this.mainRouteDependency}/treasuries`).then((response) => {
        this.treasuries = response.data.data;
      });
    },
    getBanksOption(){
      ApiService.get(`${this.mainRouteDependency}/banks`).then((response) => {
        this.banks = response.data.data;
      });
    },
    getCategories() {
      ApiService.get(`${this.mainRouteDependency}/categories`).then((response) => {
        this.categories = response.data.data;
      });
    },
    getMultiSelectTreasuries() {
      this.data.special_treasuries = [];
      this.treasuries_objs.filter((row) => {
        this.data.special_treasuries.push(row.id);
      });
    },
    getMultiSelectBanks() {
      this.data.special_banks = [];
      this.banks_objs.filter((row) => {
        this.data.special_banks.push(row.id);
      });
    },
    getMultiSelectCategories() {
      this.data.categories = [];
      this.categories_objs.filter((row) => {
        this.data.categories.push(row.id);
      });
    },
    restData() {
      this.data = {
        user_id: null,
        country_id: null,
        city_id: null,
        currency_id: null,
        inventory_id: null,
        branch_id: null,
        department_id: null,
        workstation_id: null,
        apply_default: true,
      };

      this.country = null;
      this.city = null;
      this.inventory = null;
      this.branch = null;
      this.department = null;
      this.workstation = null;
    },

    async getData() {
      await ApiService.get(`users/${this.data.user_id}`).then((response) => {

        this.data.country_id = response.data.data.country_id;
        this.data.city_id = response.data.data.city_id;
        this.data.currency_id = response.data.data.currency_id;
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.branch_id = response.data.data.branch_id;
        this.data.department_id = response.data.data.department_id;
        this.data.workstation_id = response.data.data.workstation_id;
        this.data.apply_default = response.data.data.apply_default;
        this.data.unit_id = response.data.data.unit_id;
        this.data.tax_id = response.data.data.tax_id;

        this.inventory = response.data.data.inventory;
        this.branch = response.data.data.branch;
        this.department = response.data.data.department;
        this.workstation = response.data.data.workstation;
        this.country = response.data.data.country;
        this.city = response.data.data.city;
        this.unit = response.data.data.unit;
        // this.tax = response.data.data.tax;
        this.full_name = response.data.data.full_name;

        this.data.special_treasuries = response.data.data.special_treasuries ? response.data.data.special_treasuries : [];
        if (response.data.data.special_treasuries)
          this.treasuries_objs = this.treasuries.filter((row) => response.data.data.special_treasuries.includes(row.id));

        this.data.special_banks = response.data.data.special_banks ? response.data.data.special_banks : [];
        if (response.data.data.special_banks)
          this.banks_objs = this.banks.filter((row) => response.data.data.special_banks.includes(row.id));
        if(response.data.data.categories)
          this.categories_objs = this.categories.filter((row) => response.data.data.categories.includes(row.id));
      });
    },
    getSelectInventory(event) {
      this.data.inventory_id = event ? event.id : null;
    },

    getSelectBranch(event) {
      this.data.branch_id = event ? event.id : null;
    },

    getSelectDepartment(event) {
      this.data.department_id = event ? event.id : null;

    },
    getSelectWorkstation(event) {
      this.data.workstation_id = event ? event.id : null;
    },
    getSelectUnit(event) {
      this.data.unit_id = event ? event.id : null;
    },
    getSelectTax(event) {
      this.data.tax_id = event ? event.id : null;
    },


    async getBranches() {
      await ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    async getInventories() {
      await ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getDepartments() {
      // if (filter && filter.length >= 3), {params: {filter: filter}}
      ApiService.get(`${this.mainRouteDependency}/departments`).then((response) => {
        this.departments = response.data.data;
      });
    },
    getWorkstations() {
      ApiService.get(`${this.mainRouteDependency}/workstations`).then(response => {
        this.workstations = response.data.data;
      })
    },

    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(this.mainRouteDependency + "/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      }
    },

    getCities(id) {
      ApiService.get(`${this.mainRouteDependency}/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getUnits() {
      ApiService.get(this.mainRouteDependency + "/units").then((response) => {
        this.units = response.data.data;
      });
    },
    getTaxes() {
      ApiService.get(this.mainRouteDependency + "/taxes").then((response) => {
        this.taxes = response.data.data;
      });
    },
  },

  mounted() {
    Promise.all([promise]).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: this.$t("MENU.user_Management"), route: '/users/users'},
      ]);
    })
    this.getInventories();
    this.getBranches();
    this.getDepartments();
    this.getWorkstations();
    this.getCurrencies();
    this.getUnits();
    this.getTaxes();

    let promise = this.getData();
    Promise.all([promise]).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: this.$t("MENU.user_Management"), route: '/users/users'},
        {title: this.full_name},
        {title: this.$t("MENU.default_data")},
      ]);
    })

  },
  created() {
    this.getTreasuriesOption();
    this.getBanksOption();
    this.getCategories();
  }
};
</script>


